import React from "react"
import Cart from "../components/Epharmacy/Cart"

import { initialState } from "./AppContext"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "./services/context"
import { isObjectEmpty } from "../services/general"
import layoutStyles from "../components/Layout/utils/layout.module.scss"

const AUTH = {
  SAVE_AUTH: "SAVE_AUTH",
}

const SAVE_AGREE_EPHARMACY = "SAVE_AGREE_EPHARMACY"
const SAVE_AGREE_PATIENTENROLLMENT = "SAVE_AGREE_PATIENTENROLLMENT"
const SAVE_AGREE_DOCTORENROLLMENT = "SAVE_AGREE_DOCTORENROLLMENT"
const SAVE_AGREE_CONSENT_EPHARMACY = "SAVE_AGREE_CONSENT_EPHARMACY"
const SAVE_AGREE_CONSENT_PATIENTENROLLMENT =
  "SAVE_AGREE_CONSENT_PATIENTENROLLMENT"
const SAVE_ENROLLING_USER_TYPE = "SAVE_ENROLLING_USER_TYPE"
const SAVE_ORDERING_USER_TYPE = "SAVE_ORDERING_USER_TYPE"
const SAVE_AGREE_CONSENT_DOCTORENROLLMENT =
  "SAVE_AGREE_CONSENT_DOCTORENROLLMENT"
const SAVE_MEDICINES = "SAVE_MEDICINES"
const SAVE_DOCUMENTS = "SAVE_DOCUMENTS"
const SAVE_PERSONAL_DETAILS = "SAVE_PERSONAL_DETAILS"
const SAVE_DELIVERY_DETAILS = "SAVE_DELIVERY_DETAILS"
const RESET_DETAILS = "RESET_DETAILS"
const SHOW_MODAL = "SHOW_MODAL"
const HIDE_MODAL = "HIDE_MODAL"
const SHOW_CART = "SHOW_CART"
const SAVE_COUPON = "SAVE_COUPON"
const REMOVE_COUPON = "REMOVE_COUPON"
const SHOW_TOAST = "SHOW_TOAST"
const HIDE_TOAST = "HIDE_TOAST"
const SAVE_ACTIVE_DOCUMENT = "SAVE_ACTIVE_DOCUMENT"
const REMOVE_ACTIVE_DOCUMENT = "REMOVE_ACTIVE_DOCUMENT"
const SHOW_DISCLAIMER = "SHOW_DISCLAIMER"
const HIDE_DISCLAIMER = "HIDE_DISCLAIMER"
const SAVE_SCPWD_FRONT_ID = "SAVE_SCPWD_FRONT_ID"
const SAVE_SCPWD_BACK_ID = "SAVE_SCPWD_BACK_ID"
const SAVE_CONTEXT_TO_SESSION = "SAVE_CONTEXT_TO_SESSION"
const SAVE_HEALTH_SURVEY = "SAVE_HEALTH_SURVEY"
const GET_CONTEXT_FROM_SESSION = "GET_CONTEXT_FROM_SESSION"
const REMOVE_CONTEXT_FROM_SESSION = "REMOVE_CONTEXT_FROM_SESSION"
const SAVE_PROOF_OF_PAYMENT = "SAVE_PROOF_OF_PAYMENT"
const SAVE_SHUFFLED_BARRIERS_ADHERENCE = "SAVE_SHUFFLED_BARRIERS_ADHERENCE"
const SAVE_REFILL_FORM = "SAVE_REFILL_FORM"
const SAVE_ORDERING_AS_GUEST = "SAVE_ORDERING_AS_GUEST"
const SAVE_HAS_MEDGROCER_OPTION = "SAVE_HAS_MEDGROCER_OPTION"
const SAVE_DISPENSING_CHANNELS = "SAVE_DISPENSING_CHANNELS"
const SAVE_AGREE_CONTACT_PERSON = "SAVE_AGREE_CONTACT_PERSON"
const SAVE_IS_LICENSED_PHYSICIAN = "SAVE_IS_LICENSED_PHYSICIAN"
const SAVE_MEDICATION_IN_ACCORDANCE = "SAVE_MEDICATION_IN_ACCORDANCE"

export let AppReducer = (state, action) => {
  switch (action.type) {
    case AUTH.SAVE_AUTH:
      return {
        ...state,
        auth: { ...state.auth, ...action.payload },
      }

    case SAVE_AGREE_EPHARMACY:
      return {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          agreeToTermsAndConditions: action.payload,
        },
      }

    case SAVE_AGREE_PATIENTENROLLMENT:
      return {
        ...state,
        patientEnrollment: {
          ...state.patientEnrollment,
          agreeToTermsAndConditions: action.payload,
        },
      }

    case SAVE_AGREE_DOCTORENROLLMENT:
      return {
        ...state,
        doctorEnrollment: {
          ...state.doctorEnrollment,
          agreeToTermsAndConditions: action.payload,
        },
      }

    case SAVE_AGREE_CONSENT_EPHARMACY:
      return {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          agreeToConsent: action.payload,
        },
      }

    case SAVE_AGREE_CONSENT_PATIENTENROLLMENT:
      return {
        ...state,
        patientEnrollment: {
          ...state.patientEnrollment,
          agreeToConsent: action.payload,
        },
      }

    case SAVE_ENROLLING_USER_TYPE:
      return {
        ...state,
        patientEnrollment: {
          ...state.patientEnrollment,
          submittingUserType: action.payload,
        },
      }

    case SAVE_ORDERING_USER_TYPE:
      return {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          submittingUserType: action.payload,
        },
      }

    case SAVE_AGREE_CONSENT_DOCTORENROLLMENT:
      return {
        ...state,
        doctorEnrollment: {
          ...state.doctorEnrollment,
          agreeToConsent: action.payload,
        },
      }

    case SAVE_MEDICINES:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: action.payload },
      }

    case SAVE_ORDERING_AS_GUEST:
      return {
        ...state,
        isOrderingAsGuest: action.payload,
      }
    case SAVE_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      }
    case SAVE_ACTIVE_DOCUMENT:
      return {
        ...state,
        activeDocument: { ...action.payload },
      }
    case REMOVE_ACTIVE_DOCUMENT:
      let newTempDocument = action?.payload
      let currentDocuments = [...state.documents]
      currentDocuments = currentDocuments.filter(
        (document) => document?.oldname !== newTempDocument?.oldname
      )
      currentDocuments = [...currentDocuments, newTempDocument]
      return {
        ...state,
        activeDocument: null,
        documents: currentDocuments,
      }
    case SAVE_SCPWD_FRONT_ID:
      return {
        ...state,
        scpwdId: { ...state.scpwdId, front: action.payload },
      }
    case SAVE_SCPWD_BACK_ID:
      return {
        ...state,
        scpwdId: { ...state.scpwdId, back: action.payload },
      }
    case SAVE_PROOF_OF_PAYMENT:
      return {
        ...state,
        proofOfPayment: action.payload,
      }
    case SAVE_PERSONAL_DETAILS:
      return {
        ...state,
        flexmed: { ...state.flexmed, ...action.payload },
      }

    case SAVE_DELIVERY_DETAILS:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }

    case SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }

    case SHOW_CART:
      return {
        ...state,
        ...action?.payload,
        modal: {
          ...state.modal,
          isCard: true,
          isFullheight: true,
          heading: "My Cart",
          headingClass: "has-text-weight-bold has-text-primary",
          content: (
            <div className={layoutStyles["navbar__cart"]}>
              <Cart />
            </div>
          ),
          background: { color: "white", opacity: 100 },
          isActive: true,
        },
      }

    case SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case SHOW_DISCLAIMER:
      return { ...state, disclaimer: { ...initialState.disclaimer } }

    case HIDE_DISCLAIMER:
      return { ...state, disclaimer: { ...action.payload, isActive: false } }

    case SAVE_COUPON:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, coupon: action.payload },
      }

    case REMOVE_COUPON:
      return { ...state, epharmacy: { ...state.epharmacy, coupon: null } }

    case RESET_DETAILS:
      return { ...initialState }

    case SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved
    case GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }

      return {
        ...returnState,
        patientEnrollment: {
          ...returnState.patientEnrollment,
          agreeToConsent: [],
        },
        doctorEnrollment: {
          ...returnState.doctorEnrollment,
          agreeToConsent: [],
        },
        modal: {
          ...initialState.modal,
        },
      }

    case REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }

    case SAVE_SHUFFLED_BARRIERS_ADHERENCE:
      return { ...state, adherenceLikert: action.payload }

    case SAVE_HEALTH_SURVEY:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, healthSurvey: action.payload },
      }

    case SAVE_REFILL_FORM:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }

    case SAVE_HAS_MEDGROCER_OPTION:
      return {
        ...state,
        epharmacy: { ...state.epharmacy, ...action.payload },
      }

    case SAVE_DISPENSING_CHANNELS:
      return {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          dispensingChannels: [...action.payload],
        },
      }

    case SAVE_AGREE_CONTACT_PERSON:
      return {
        ...state,
        epharmacy: {
          ...state.epharmacy,
          agreeContactPerson: [...action.payload],
        },
      }

    case SAVE_IS_LICENSED_PHYSICIAN:
      return {
        ...state,
        doctorEnrollment: {
          ...state.doctorEnrollment,
          isLicensedPhysician: [...action.payload],
        },
      }

    case SAVE_MEDICATION_IN_ACCORDANCE:
      return {
        ...state,
        doctorEnrollment: {
          ...state.doctorEnrollment,
          medicationInAccordance: [...action.payload],
        },
      }
    default:
      return
  }
}
